<template>
    <div>
        <Carousel :banner-list="bannerLists"/>
        <div class="mainwidth">
            <div class="news-top">
                <div class="news-nav ">
                    <ul>
                        <li :class="{liactive:item.id === newsnavId}" v-for="item in newsnavTabs" :key="item.id" @click="onTab(item)">{{ item.label }}</li>
                    </ul>
                    <div>
                        <a-input-search
                            v-model:value="searchVal"
                            placeholder="请输入搜索关键词"
                            @search="onSearch"
                        />
                    </div>
                </div>
                <ul class="search-con" v-if="newsnavId === 1">
                    <li>
                        <p class="p-one">政策等级：</p>
                        <p class="p-two">
                            <span :class="{'sp-active': pLevel === ''}" @click="pLevel = ''">不限</span>
                            <span :class="{'sp-active': pLevel === '国'}" @click="pLevel = '国'">国家</span>
                            <span :class="{'sp-active': pLevel === '省'}" @click="pLevel = '省'">省级</span>
                            <span :class="{'sp-active': pLevel === '市'}" @click="pLevel = '市'">市级</span>
                            <span :class="{'sp-active': pLevel === '区'}" @click="pLevel = '区'">区域</span>
                        </p>
                    </li>
                    <li>
                        <p class="p-one">关键词语：</p>
                        <p class="p-two">
                            <span :class="{'sp-active': inforKye === ''}" @click="inforKye = ''">不限</span>
                            <span :class="{'sp-active': inforKye === '融资担保'}" @click="inforKye = '融资担保'">融资担保</span>
                            <span :class="{'sp-active': inforKye === '减税降费'}" @click="inforKye = ' 减税降费'"> 减税降费</span>
                            <span :class="{'sp-active': inforKye === '中小企业'}" @click="inforKye = '中小企业'">中小企业</span>
                            <span :class="{'sp-active': inforKye === '产业扶持'}" @click="inforKye = '产业扶持'">产业扶持</span>
                            <span :class="{'sp-active': inforKye === '政府补贴'}" @click="inforKye = '政府补贴'">政府补贴</span>
                            <span :class="{'sp-active': inforKye === '创新创业'}" @click="inforKye = '创新创业'">创新创业</span>
                          <span :class="{'sp-active': inforKye === '金融扶持'}" @click="inforKye = '金融扶持'">金融扶持</span>
                          <span :class="{'sp-active': inforKye === '数字经济'}" @click="inforKye = '数字经济'">数字经济</span>
                          <span :class="{'sp-active': inforKye === '疫情防控'}" @click="inforKye = '疫情防控'">疫情防控</span>
                          <span :class="{'sp-active': inforKye === '贴息政策'}" @click="inforKye = '贴息政策'">贴息政策</span>
                          <span :class="{'sp-active': inforKye === '招商引资'}" @click="inforKye = '招商引资'">招商引资</span>
                        </p>
                    </li>
                    <li>
                        <a-button @click="getPolicyList">确定筛选</a-button>
                    </li>
                </ul>
            </div>
            <ul class="news-con" v-show="newsnavId === 1">
               <li v-for="(pItem, pi) in pList" :key="pi">
                   <div class="item-left">
                       <img :src="imgApi + pItem.FILE_ADDR" style="width: 100%;height: 160px;">
                   </div>
                   <a-divider type="vertical" style="height:auto;margin:0 15px" />
                   <div class="item-right">
                       <div class="item-right-top">
<!--                              <div style="display: flex;align-items: center">-->
                                  <div class="title">{{pItem.INFOR_TITLE}}</div>
                                  <div style="display: flex;align-items: center">
                                      <div class="type type-guo" v-if="pItem.POLICY_LEVEL == '国'">{{pItem.POLICY_LEVEL}}</div>
                                      <div class="type type-guo" v-if="pItem.POLICY_LEVEL == '省'" style="background: #ffb83c">{{pItem.POLICY_LEVEL}}</div>
                                      <div class="type type-guo" v-if="pItem.POLICY_LEVEL == '市'" style="background: #9ad86c">{{pItem.POLICY_LEVEL}}</div>
                                      <div class="type type-guo" v-if="pItem.POLICY_LEVEL == '区'" style="background: #8fa1f9">{{pItem.POLICY_LEVEL}}</div>
                                      <div class="time">{{pItem.PROM_DATE}}</div>
                                  </div>
<!--                              </div>-->
                           <a-button @click="$router.push({ path: '/PolicyInformationDetails' , query: { id: pItem.INFORMATON_ID }})">查看详情</a-button>
                       </div>
                       <div class="item-right-bot">
                           <p style="line-height: 33px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">{{pItem.INFOR_INTRO}}</p>
                       </div>
                   </div>
               </li>
            </ul>
            <ul class="news-con" v-show="newsnavId === 2">
               <li v-for="(nItem, ni) in nList" :key="ni">
                   <div class="item-left">
                       <img :src="imgApi + nItem.FILE_ADDR" style="width: 100%;height: 160px;">
                   </div>
                   <a-divider type="vertical" style="height:auto;margin:0 15px" />
                   <div class="item-right">
                       <div >
                           <div class="item-right-top">
<!--                               <div style="display: flex;align-items: center">-->
                                   <div class="title">{{nItem.INFOR_TITLE}}</div>
                                   <div class="time">{{nItem.PROM_DATE}}</div>
<!--                               </div>-->
                               <a-button @click="$router.push({ path: '/PolicyInformationDetails' , query: { id: nItem.INFORMATON_ID }})">查看详情</a-button>
                           </div>
                       </div>
                       <div class="item-right-bot">
                           <p style="line-height: 33px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">{{nItem.INFOR_INTRO}}</p>
                       </div>
                   </div>
               </li>
            </ul>
            <div class="page-box">
                <a-pagination show-quick-jumper v-model:current="page" :total="total" @change="onChange" />
            </div>
        </div>
    </div>
</template>

<script>
    import Carousel from '../components/Carousel'

    import Base from '@/api/base.js'
export default {
        components:{Carousel},
    data() {
        return {
            bannerLists: [require('../assets/images/proBanner2.jpg')],
            imgApi: Base.cs + '/',
            page: 1,
            newsnavTabs: [
                {
                    label: '政策指引',
                    id: 1
                },
                {
                    label: '资讯动态',
                    id: 2
                }
            ],
            newsnavId: 1,
            total: 0,
            pList: [],
            nList: [],
            searchVal: '',
            inforKye: '',
            pLevel: ''
        }
    },
    methods: {
        onTab (item) {
            this.newsnavId = item.id
            this.page = 1
            this.total = 0
            this.searchVal = ''
            if (item.id == 1) {
                this.pList = []
                this.getPolicyList()
            } else {
                this.nList = []
                this.getNewsList()
            }
        },
        onSearch () {
            if (this.newsnavId == 1) {
                this.getPolicyList()
            } else {
                this.getNewsList()
            }
        },
        onChange (pageNumber) {
            console.log('Page: ', pageNumber)
            this.page = pageNumber
            if (this.newsnavId == 1) {
                this.getPolicyList()
            } else {
                this.getNewsList()
            }
        },
        // 获取政策指引列表
        getPolicyList () {
            this.$store.dispatch('getPolicyList', {
                showCount: 10,
                currentPage: this.page,
                TITLE_KEY: this.searchVal,
                INFOR_KEY: this.inforKye,
                POLICY_LEVEL: this.pLevel,
                XCX_NAME:localStorage.getItem('xcxName'),
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pList = res.varList
                        this.total = res.page.totalResult
                    } else {
                        this.pList = []
                        this.total = 0
                        this.page = 1
                    }
                } else {
                    this.pList = []
                    this.total = 0
                    this.page = 1
                }
            })
        },
        // 获取咨询动态列表
        getNewsList () {
            this.$store.dispatch('getNewsList', { showCount: 10, XCX_NAME:localStorage.getItem('xcxName'), currentPage: this.page, TITLE_KEY: this.searchVal }).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.nList = res.varList
                        this.total = res.page.totalResult
                    } else {
                        this.nList = []
                        this.total = 0
                        this.page = 1
                    }

                } else {
                    this.nList = []
                    this.total = 0
                    this.page = 1
                }
            })
        }
    },
    created () {
        if (this.$route.query.tab == 1) {
            this.onTab(this.newsnavTabs[0])
        } else if (this.$route.query.tab == 2) {
            this.onTab(this.newsnavTabs[1])
        }
        this.getPolicyList()
    }
}
</script>

<style lang="less" scoped>
.news-nav ::v-deep(.ant-input){
  height: 30px;
  font-size:16px;
  width: 400px;
}
.item-right-top ::v-deep(.ant-btn){
    padding:0 42px;
    color: #ffffff;
    height: 40px;
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.search-con ::v-deep(.ant-btn){
    margin: 0 auto;
    padding:0 42px;
    color: #ffffff;
    height: 40px;
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
</style>
<style lang="less" scoped>
.news-banner{
    img{
        width: 100%;
    }
}
.news-top{
    margin-top: 30px;
    padding: 15px 10px;
    background-color:@color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    position: relative;
    font-size: @font-xl;
    .news-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0;
            li{
                height: 40px;
                line-height: 40px;
                padding: 0 30px;
                border: 1px solid @color-blue;
                margin: 0 20px;
                border-radius: 20px;
                cursor: pointer;
            }
            .liactive{
                color: @color-ff;
                background-color: @color-blue;
            }
            li:hover{
                color: @color-ff;
                background-color: @color-blue;
            }
        }
        div{
            margin-right: 20px;
        }
    }
    .search-con{
        margin-top: 15px;
        margin-bottom: 0;
        li{
            font-size: @font-lg;
            padding:10px 0px;
            border-bottom: @border-base;
            display: flex;
            justify-content: flex-start;
            p{
                margin-bottom: 0;
                font-size: @font-lg;
            }
            .p-one{
                width: 120px;
                padding-top: 2px;
                margin:5px 10px;
            }
            .p-two{
                width: 1156px;
                span{
                    display: inline-block;
                    padding: 2px 20px;
                    margin:5px 10px;
                    border-radius: 2px;
                    cursor: pointer;
                    transition: all .1s ease;
                    -moz-transition:all .1s ease; /* Firefox 4 */
                    -webkit-transition:all .1s ease; /* Safari 和 Chrome */
                    -o-transition:all .1s ease; /* Opera */
                }
                span:hover{
                    color: @color-ff;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                }
                .sp-active{
                    color: @color-ff;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                }
            }
        }
    }
}
.news-top::before{
    content: '';
    position: absolute;
    left: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
}
.news-top::after{
    content: '';
    position: absolute;
    right: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
}
.news-con{
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: @color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    li{
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: @border-base;
        .item-left{
            width: 284px;
            img{
                width: 100%;
            }
        }
        .item-right{
            width: 950px;
            .item-right-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                    margin-bottom: 0;
                   .title{
                       width: 570px;
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                        display: block;
                        font-size: 22px;
                        font-weight: bold;
                   }
                   .type{
                       width: 20px;
                       height: 20px;
                       font-size: @font-lg;
                       background-color: @color-guo;
                       color: @color-ff;
                       border-radius: 2px;
                       margin-right: 10px;
                   }
                   .type-guo{
                       text-align: center;
                       line-height: 20px;
                       width: 20px;
                       height: 20px;
                       background-color: @color-guo;
                       color: @color-ff;
                   }
                   .time{
                       font-size: @font-lg;
                       color: @color-base;
                   }
            }
            .item-right-bot{
                display: flex;
                justify-content: start;
                flex-wrap: wrap;
                border-top: @border-ea;
                padding-top: 10px;
                p{
                    margin-bottom: 0;
                    line-height: 28px;
                    font-size: @font-lg;
                    color: @color-base;
                }
            }
        }
    }
    li:first-child{
        padding-top: 0;
    }
}
.page-box{
    padding: 15px 20px;
    background-color: @color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    margin-bottom: 30px;
    position: relative;
    .ant-pagination{
        display: flex;
        justify-content: center;
    }
}
.page-box::before{
    content: '';
    position: absolute;
    left: -3px;
    top: 11px;
    width: 6px;
    height: 40px;
    background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
}
.page-box::after{
    content: '';
    position: absolute;
    right: -3px;
    top: 11px;
    width: 6px;
    height: 40px;
    background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
}
</style>
